import {graphql} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import React from "react";

import HeadTwo from "../../components/HeadTwo/HeadTwo";
import Layout from "../../components/Layout";
import StructuredData, {
  StructuredDataType,
} from "../../components/StructuredData";
import Benefits from "../../components/pages/partners/Benefits";
import PartnerFooter from "../../components/pages/partners/Footer";
import PartnerRoleHero from "../../components/pages/partners/RoleHero";
import PartnerSteps from "../../components/pages/partners/Steps";

const heroData = {
  image: (
    <StaticImage
      src='https://media.kubric.io/api/assetlib/e3a41386-9dff-4ed3-93c6-f5b013493793.png'
      alt=''
    />
  ),
  mobileImage: (
    <StaticImage
      src='https://media.kubric.io/api/assetlib/fac18576-1447-41df-9612-d3c55427642b.png'
      alt=''
    />
  ),
  content: (
    <div>
      <p>Collaboration can transform good SaaS solutions into great ones!</p>

      <p className='blueB'>
        We're always on the lookout for technology partners who are as
        determined to <b>enhance the eCommerce ecosystem</b> as we are.
      </p>

      <p className='blueB'>
        Join in the <b>Mason Tech Early Adopter Program</b>, and let's build the
        next-generation of seamless shopping experiences together.
      </p>
    </div>
  ),
};

const benifits = [
  {
    icon: (
      <StaticImage
        src='https://media.kubric.io/api/assetlib/700fca87-1ace-4ce6-baf6-20ba482167a7.png'
        alt='benifit'
      />
    ),
    color: "#787DF7",
    title: "Easy Integration",
    p1: (
      <p>
        Customized integration options with our low-code plug & play platform.
      </p>
    ),
  },
  {
    icon: (
      <StaticImage
        src='https://media.kubric.io/api/assetlib/077e8aac-174c-4b62-b2f1-a0b6fae3c47b.png'
        alt='benifit'
      />
    ),
    color: "#787DF7",
    title: "Developer Hub",
    p1: (
      <p>Dedicated developer partner to help with any integration issues. </p>
    ),
  },
  {
    icon: (
      <StaticImage
        src='https://media.kubric.io/api/assetlib/2df960cb-b82c-446e-be38-7c7b6c371ede.png'
        alt='benifit'
      />
    ),
    color: "#787DF7",
    title: "Co-marketing",
    p1: (
      <p>
        Events, content, and more collaborative initiatives to build brand
        presence.
      </p>
    ),
  },
];

const steps = [
  "Fill out the partner application form. (It takes ~30 seconds!)",
  "Get matched with your dedicated account manager within 24 hours. ",
  "Start collaborating and #SpreadTheMagic together.",
];

const ShopifyApp = ({data}) => {
  const seoData = data.allSanitySeoMeta.nodes[0];
  return (
    <Layout seoData={seoData}>
      <StructuredData
        type={StructuredDataType.BREADCRUMB}
        data={{
          name: "Shopify Partners | Mason",
          items: [
            {
              name: "Shopify Partners | Mason",
              item: "https://getmason.io/partners/shopifyapp",
            },
          ],
        }}
      />
      <PartnerRoleHero {...heroData} />
      <div className='mt-24'>
        <HeadTwo>
          Agency Partner <b>benefits</b>
        </HeadTwo>
      </div>
      <Benefits data={benifits} />
      <PartnerSteps data={steps} />
      <PartnerFooter color='#787DF7' />
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "shopifyapp"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
  }
`;

export default ShopifyApp;
